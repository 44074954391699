// @import "~bootstrap-v4-rtl/scss/bootstrap-rtl.scss";
@import url('https://fonts.googleapis.com/css?family=Cairo&display=swap');
/**
Global properties
*/
html,
body {
    direction: rtl;
    text-align: right;
    font-family: 'Cairo', sans-serif;
    letter-spacing: 0;
}

.mR-10 {
    margin-right: 0 !important;
    margin-left: 10px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Cairo', sans-serif;
    letter-spacing: 0;
}

input {
    text-align: right;
}

.list-inline {
    padding-right: 0;
}

.page-container {
    padding-left: 0;
    padding-right: 280px;
}

.header .header-container .nav-left {
    float: right;
}

.header .header-container .nav-left>li,
.header .header-container .nav-right>li {
    float: right;
}

.header .header-container .nav-right {
    float: left;
}

.navbar {
    padding: 0;
}

.sidebar-menu {
    border-left: 1px solid rgba(0, 0, 0, 0.0625);
    border-right: none;
}

.header .header-container .nav-right .dropdown-menu {
    right: auto;
    left: 0;
}

.dataTables_wrapper .dataTables_filter input {
    margin-left: 0;
    margin-right: 15px;
}

.dataTables_wrapper .dataTables_length {
    float: right;
}

.dataTables_wrapper .dataTables_filter {
    float: left;
}

.sidebar-logo {
    border-left: 1px solid rgba(0, 0, 0, 0.0625);
    border-right: none;
}

.is-collapsed .page-container {
    padding-right: 70px;
    padding-left: 0;
}

.sidebar-menu>li>a .icon-holder {
    margin-right: 0;
}

button.close {
    float: left;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    float: right;
}

.select2-container {
    direction: rtl;
    text-align: right;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    float: right !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    float: left !important;
}

.list-group{
    .list-group-item{
        .checkbox{
            label{
                padding-right: 20px;
                &:before, &:after{
                    left: unset;
                    right: 0;
                }
            }
        }
    }
}

/**
Properties relative to desktop screen
*/
@media screen and (max-width: 1439px) and (min-width: 992px) {
    .page-container {
        padding-left: 0px;
        padding-right: 70px;
    }
    .is-collapsed {
        .page-container {
            padding-right: 280px;
        }
    }
    .header{
        .header-container{
            .nav-right{
                .dropdown-menu{
                    &>li{
                        &>a{
                            display: flex!important;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {}

@media screen and (max-width: 991px) {}

@media screen and (max-width: 767px) {
  .header .header-container .nav-left {
    padding: 0 15px 0 0;
  }

  .page-container {
    padding-right: 0;
  }

  .is-collapsed {
    .page-container {
      padding-right: 0;
    }

    .sidebar {
      right: 0;
      left: inherit;
    }
  }

  .sidebar-logo .mobile-toggle a {
    color: #323535;
    transform: rotate(180deg);
    margin: 10px;
  }

  .sidebar {
    right: -280px;
    left: inherit;
  }

  h4 {
    font-size: 16px;
  }

  .nav-tabs .nav-link {
    padding: 5px 10px;
    font-size: 12px;
  }
}

@media (min-width: 576px){
.modal-dialog {
    max-width: 90%;
    }
}
@media screen and (max-width: 480px) {}